import type { ErrorIdent } from '~/@types/errorIdents';
import { useSecureSessionPost } from '@/composables/dataFetching/genericFetchers';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import type { Result } from '@/server/api/[site]/user/verficationFile/upload.post';
import { handleLoadingError } from '~/utils/handleLoadingError';

export function useUploadVerificationFile() {
  const { t } = useTrans();
  const dialogStore = useDialogStore();
  const site = useSiteIdent();
  const errors = ref<ErrorIdent[]>([]);
  dialogStore.setHeadline(t('user.verificationFile.uploadDialog.headline'));

  const isLoading = ref(false);

  async function onSubmit() {
    isLoading.value = true;
    await uploadVerificationFiles();
    isLoading.value = false;
  }

  async function uploadVerificationFiles() {
    try {
      const fileInputs = document.querySelectorAll('[name^="files"]');
      const body = new FormData();
      let hasFiles = false;
      fileInputs.forEach((input) => {
        if ((input as HTMLInputElement)?.files?.length) {
          hasFiles = true;
          body.append(
            (input as HTMLInputElement).name,
            (input as HTMLInputElement)?.files?.[0],
          );
        }
      });

      if (hasFiles) {
        await useSecureSessionPost<Result>(
          `/api/${site}/user/verficationFile/upload`,
          body,
        );

        dialogStore.closeDialog(DialogResponses.SUCCESS);
      }
      isLoading.value = false;
    } catch (e: any) {
      errors.value = e?.data?.data?.errors ?? [];
      document.querySelector('.js-error-container')?.scrollIntoView({
        behavior: 'smooth',
      });
      isLoading.value = false;
      handleLoadingError(e);
    }
  }
  return {
    onSubmit,
    isLoading,
    errors,
  };
}
